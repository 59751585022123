body {
  margin: 0;
  font-family: "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout-sider-trigger {
  border-right: 1px solid #f3f3f3;
}

.ant-table table {
  background-color: #f8f9fb;
  border-spacing: 0 10px;
}

.app-table-row {
  background-color: #ffffff;
}

.ant-btn-primary {
  display: flex;
}

.ant-popconfirm-buttons {
  display: flex;
}
